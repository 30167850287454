import { Worker, Viewer } from "@react-pdf-viewer/core";
import { useParams } from "react-router-dom";
import { useMemo, useState } from "react";

import styles from "./pdf.module.css";

import cimatronCad from "./pdfs/cimatron-cad-pdf.pdf";
import cimatronDie from "./pdfs/cimatron-die-pdf.pdf";
import cimatronElectrode from "./pdfs/cimatron-electrode-pdf.pdf";
import cimatronMold from "./pdfs/cimatron-mold-pdf.pdf";
import cimatronNcProgramming from "./pdfs/cimatron-nc-programming-pdf.pdf";
import cimatronViewer from "./pdfs/cimatron-viewer-pdf.pdf";
import { SimpleLoader } from "@components/loaders/simple_loader";

const PDF_DATA = {
  "cimatron-cad-pdf": cimatronCad,
  "cimatron-die-pdf": cimatronDie,
  "cimatron-electrode-pdf": cimatronElectrode,
  "cimatron-mold-pdf": cimatronMold,
  "cimatron-nc-programming-pdf": cimatronNcProgramming,
  "cimatron-viewer-pdf": cimatronViewer,
};

export const PdfRender = () => {
  const [fileUrl, setFileUrl] = useState(null);

  const { id } = useParams();
  useMemo(() => {
    const fileUrl = PDF_DATA[id];
    setFileUrl(fileUrl);
  }, [id]);
  return (
    <>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <div className={`${styles.pdfWrapper}`}>
          <div className={`${styles.pdfContainer}`}>
            <Viewer
              fileUrl={fileUrl}
              renderLoader={() => <SimpleLoader showText={false} />}
            />
          </div>
        </div>
      </Worker>
    </>
  );
};
