import { idConverter } from "@common/util";

import texpo2024Img from "./images/texpo-2024-img.jpg";
import cimatron_grc_2024_img from "./images/cimatron-grc-2024-img.jpg";
import cimatron_grc_2022_img from "./images/cimatron-grc-2022-img.jpg";

// ---------- categories ----------
export const CATEGORIES = {
  ALL_BLOGS: "All Blogs",
  EVENTS: "Event posts",
  // WEBINARS: "Webinar posts",
};

// ---------- blog data ----------
export const BLOG_DATA = [
  {
    id: idConverter(
      "The Unisys Technologies TEXPO 2024, which was a landmark event for Micro Small"
    ),
    category: CATEGORIES.EVENTS,
    title:
      "The Unisys Technologies TEXPO 2024, which was a landmark event for Micro Small",
    image: texpo2024Img,
    intro:
      "The Unisys Technologies TEXPO 2024, which was a landmark event for Micro Small, and Medium Enterprises took place at Texpo Park Chennai from 07th to 09th August 2024...",
    fullDetails: `<p><span style="color: #3d3d3d;"><span class="s4"><span style="color: #e03e2d;">The Unisys Technologies TEXPO 2024</span>, which was a landmark event for Micro Small, and Medium Enterprises took place at Texpo Park Chennai from 07th to 09th </span><span class="s4">August 2024</span><span class="s4">. </span><span class="s4">Inaugurated</span><span class="s4"> by the Hon&rsquo;ble T. M. Anbarasan, the Minister of Micro, Small &amp; Medium Enterprises, Government of Tamil Nadu, the event was a three-day exhibition of innovative technologies, approaches</span> <span class="s4">and solutions in different sectors.</span><span class="s4"><br /></span></span></p>
<p><span style="color: #3d3d3d;"><span class="s4"><span style="color: #e03e2d;">The TEXPO</span> offered the potential of business software, automation technology, and manufacturing systems where one could see what was new in the market. It also provided expos for business professionals, new startups, and entrepreneurs to meet each other and find some business partners. The event with no charges for registration was embraced by a good number of </span><span class="s4">audiences</span><span class="s4">. </span></span></p>
<p>&nbsp;</p>
<p><span class="s4" style="color: #3d3d3d;">TEXPO 2024 paved the way for the growth and development of its numerous <span style="color: #e03e2d;">MSME</span> participants from Tamil Nadu and concluded on an optimistic tone with a promising outlook in store for MSMEs which envisages harnessing technological advancement and innovations.</span></p>`,
    date: "August 2, 2024",
  },
  {
    id: idConverter(
      "The spectacular island of Ibiza was the venue for the much-anticipated Cimatron GRC 2024"
    ),
    category: CATEGORIES.EVENTS,
    title:
      "The spectacular island of Ibiza was the venue for the much-anticipated Cimatron GRC 2024",
    image: cimatron_grc_2024_img,
    intro:
      "The spectacular island of Ibiza was the venue for the much-anticipated Cimatron GRC 2024 from May 12th to May 15th, 2024. Unisys Technologies made it presence in Cimatron GRC 2024....",
    fullDetails: `<p><span style="color: #3d3d3d;"><span style="color: #e03e2d;">The spectacular island of Ibiza </span>was the venue for the much-anticipated <span style="color: #e03e2d;">Cimatron GRC 2024</span> from May 12th to May 15th, 2024. Unisys Technologies made it presence in Cimatron GRC 2024.</span><br /><span style="color: #3d3d3d;">The main goal of the event was to provide interactions between Cimatron Global Reseller Community &amp; exchange their views on different market scenarios &amp; how to handle the competitiveness. &nbsp;</span><br /><span style="color: #3d3d3d;">Demonstrations were given for Cimatron 2025 and Roadmap V2026 &amp; beyond and overview of the upcoming new die cost estimator product and Future integration of Fikus Wire EDM into Cimatron from Metalcam.</span><br /><span style="color: #3d3d3d;">&nbsp;</span><br /><span style="color: #3d3d3d;">Topics covered on API Tools , AI in Cimatron &amp; Future New Licensing Mechanism and Cloud computing for Die Cost Estimator.</span><br /><span style="color: #3d3d3d;">&nbsp;</span><br /><span style="color: #3d3d3d;"><span style="color: #e03e2d;">TEKYAZ from Turkey</span> , MBS from Thailand showcased their market, competition, and success with Cimatron.</span><br /><span style="color: #3d3d3d;">Unisys Technologies continues to set the standard for technological innovation and mold the direction of IT solutions, stay tuned for additional developments from the company.</span></p>`,
    date: "May 15, 2024",
  },

  {
    id: idConverter(
      "After being the Authorized Distributor in 2020 Unisys Technologies made a notable impact at the Cimatron GRC 2022 Conference"
    ),
    category: CATEGORIES.EVENTS,
    title:
      "After being the Authorized Distributor in 2020 Unisys Technologies made a notable impact at the Cimatron GRC 2022 Conference",
    image: cimatron_grc_2022_img,
    intro:
      "After being the Authorized Distributor in 2020 Unisys Technologies made a notable impact at the Cimatron GRC 2022 Conference, held in Barcelona from September 18th to 21st, 2022...",
    fullDetails: `After being the Authorized Distributor in 2020 Unisys Technologies made a notable impact at the Cimatron GRC 2022 Conference, held in Barcelona from September 18th to 21st, 2022. The event was a key gathering of worldwide Cimatron Resellers.

The main highlights of the conference included hands-on demonstrations, practical sessions, and engaging panel discussions. Topics ranged from Cimatron V16 and 2024 Review & upcoming new features of Cimatron across all the modules , Marketing , CGTech , TDM Systems , Cribwise. These discussions offered valuable insights and sparked conversations about future technological developments.

The conference also provided ample networking opportunities, fostering collaboration, and new business opportunities.

Unisys Technologies received award on Outstanding Achievement of the years 2021 at Cimatron GRC 2022. Cimatron GRC 2022 Conference is the 1st Cimatron GRC held after Sandvik acquires Cimatron in July 2021.

Sandvik Group

Sandvik is a high-tech and global engineering group offering products and services that enhance customer productivity, profitability and sustainability. We hold world-leading positions in selected areas – tools and tooling systems for metal cutting; equipment and tools, service and technical solutions for the mining industry and rock excavation within the construction industry; products in advanced stainless steels and special alloys as well as products for industrial heating. In 2020, the Group had approximately 37,000 employees and revenues of about 86 billion SEK in more than 160 countries within continuing operations.`,
    date: "September 21, 2022",
  },
];
