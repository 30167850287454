import React from "react";

import styles from "./loader.style.module.css";

export const SimpleLoader = ({ showText = true }) => {
  return (
    <div className={styles.loaderContainer}>
      <div className={styles.circle}></div>
      {showText && <p>Loading</p>}
    </div>
  );
};
