import React from "react";

import { ImageLazyLoader } from "@components/lazy_loaders/image_lazy_loader";
import { AOS_ANIMATION } from "@common/aos_animation";

import styles from "./banner.style.module.css";

export const PageBanner = ({
  image,
  customContent,
  content,
  opacity = "60%",
  className,
  height = "65vh",
}) => {
  return (
    <React.Fragment>
      <div
        style={{ "--banner-brightness": opacity, "--banner-height": height }}
        className={`${styles.bannerWrapper} ${className}`}
      >
        <ImageLazyLoader src={image} className={styles.image} />

        <div className={styles.centerContainer}>
          {/* -------------- custom content --------------- */}
          {customContent && customContent()}

          {/* -------------- content --------------- */}
          {content && (
            <h1
              className={styles.contentName}
              data-aos={AOS_ANIMATION.FADE_UP.TYPE}
              data-aos-duration={AOS_ANIMATION.FADE_UP.DURATION}
            >
              {content}
            </h1>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
