import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

import { BLOG_DATA } from "../constants";
import { PageBanner } from "@components/banners/page_banner";

import styles from "./blogdetails.style.module.css";

import BlogBannerImg from "@images/blog/blog-banner-img.jpeg";

export const BlogDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const blogInfo = BLOG_DATA.find((blog) => blog.id === id);

  return (
    <React.Fragment>
      {/* ------- banner section --------- */}
      <PageBanner content="Events and Webinars" image={BlogBannerImg} />

      {/* ------- blog details section --------- */}
      <div className={styles.blogWrapper}>
        <div className={styles.blogContainer}>
          <div className={styles.header}>
            <p
              className={`text-18 weight-500 ${styles.blogText}`}
              onClick={() => navigate("/events-webinars")}
            >
              Blogs
            </p>
            <MdOutlineArrowForwardIos className={styles.icon} />
            <p className={`text-18 weight-400 ${styles.blogTitle}`}>
              {blogInfo.title}
            </p>
          </div>
          <div className={styles.blogDetails}>
            <p className={styles.blogDate}>{blogInfo.date}</p>
            <div dangerouslySetInnerHTML={{ __html: blogInfo.fullDetails }} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
