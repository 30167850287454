import { ImageLazyLoader } from "@components/lazy_loaders/image_lazy_loader";

import Logo from "@images/logo/logo.png";

import styles from "./loader.style.module.css";

export const LoaderWithLogo = () => {
  return (
    <div className={styles.loaderWrapper}>
      <div className={styles.circle}></div>
      <div className={styles.imageContainer}>
        <ImageLazyLoader src={Logo} />
        {/* <p>Unisys Technology</p> */}
      </div>
    </div>
  );
};
