import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import AOS from "aos";
import { Worker } from "@react-pdf-viewer/core";

import { LoaderWithLogo as Loader } from "@components/loaders/logo_loader";
import { EntryProvider } from "@components/entry_provider";
import { WhataApp } from "@components/whatsapp";
import SuspenseLoader from "@components/lazy_loaders/suspense_lazy_loader";
import { BlogDetails } from "@components/blog/blog_details";
import { PdfRender } from "@pages/products/pdf-viewer";

// Lazy-loaded components
const NotFound = SuspenseLoader(
  React.lazy(() => import("@components/page_not_found"))
);
const Home = SuspenseLoader(React.lazy(() => import("@pages/home")));
const About = SuspenseLoader(React.lazy(() => import("@pages/about")));
const Contact = SuspenseLoader(React.lazy(() => import("@pages/contact")));
const ProductCimatron = SuspenseLoader(
  React.lazy(() => import("@pages/products/cimatron"))
);
const ProductGibbscam = SuspenseLoader(
  React.lazy(() => import("@pages/products/ares-commander"))
);
const Blogs = SuspenseLoader(React.lazy(() => import("@pages/blogs")));

function App() {
  const location = useLocation();
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {
    setIsLoader(true);

    setTimeout(() => {
      setIsLoader(false);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    AOS.init({
      offset: 100,
    });
  }, []);

  return (
    <EntryProvider>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        {isLoader && <Loader />}
        {!isLoader && (
          <React.Fragment>
            <WhataApp />

            {/* routes */}
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/products/cimatron" element={<ProductCimatron />} />
              <Route path="/products/cimatron/:id" element={<PdfRender />} />
              <Route
                path="/products/ares-commander"
                element={<ProductGibbscam />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/events-webinars" element={<Blogs />} />
              <Route path="/events-webinars/:id" element={<BlogDetails />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </React.Fragment>
        )}
      </Worker>
    </EntryProvider>
  );
}

export default App;
